import Skeleton from "react-loading-skeleton"
import { Card, Header, Content, ContentImage, CarouselContainer, ImageSlide, BoxImage, ContainerPdfs } from './styles';

export default function LoadingSkeleton() {
  return (
    <>
      <Card>
        <Header>
          <Skeleton height={20} width={'100%'} />
          <Skeleton height={20} width={'30%'} style={{ marginTop: 10 }} />
        </Header>
        <Content hasImage={false}>
          <Skeleton height={200} width={'100%'} />
        </Content>
      </Card>
      <Card>
        <Header>
          <Skeleton height={20} width={'100%'} />
          <Skeleton height={20} width={'30%'} style={{ marginTop: 10 }} />
        </Header>
        <Content hasImage={false}>
          <Skeleton height={200} width={'100%'} />
        </Content>
      </Card>
    </>
  )
}