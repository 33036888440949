import styled from "styled-components"

export const Container = styled.div`
  max-width: 100%; 
  width: 100%; 
  display: flex;
  flex-direction: column;  
`

export const FilterOptionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  height: 45px;
  margin-bottom: 20px;
`

export const FilterSelect = styled.select`
  background: #eee;
  border: none;
  outline: none;
  padding: 0 5px;
  border-radius: 4px;
`


export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: px 0;
  font-size: .9rem;
  height: 100%;
  width: 200px;
`


export const TextInput = styled.input`
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid #aaa;
  padding: 5px 0;
`


export const FilterLink = styled.a`
  margin-top: 3px;
  :hover{
    cursor: pointer;
  }
`



export const EmptyListMessage = styled.span`
  align-self: center;
  letter-spacing: 1px;
  margin-top: 30px;
`


export const CompanyFilterContainer = styled.div`
  display: flex;
  gap: 10px;
`



interface ICompanyFilterProps {
  selected?: boolean
}

export const CompanyFilter = styled.button<ICompanyFilterProps>`
  border: none;
  background: ${({ selected, theme }) => selected ? theme.colors.darkSilver: theme.colors.white};
  color: ${({ selected, theme }) => selected ? theme.colors.white : theme.colors.darkSilver};
  ${({ selected, theme }) => !selected && `box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;`}
  padding: 10px;
  border-radius: 12px;

  :hover{
    cursor: pointer;
  }
`