
import { IStorageFilter } from "../@interfaces";

export class TempStorage {

  public async setOcorrenciaFilter(dataInicial: string, dataFinal: string, idCliente: number) {
    try {
      const data = {
        dataInicial,
        dataFinal,
      }
      sessionStorage.setItem("@portalCliente_OcorrenciaFilter", JSON.stringify(data));
    } catch (error) {
      throw new Error("setOcorrenciaFilter: " + error)
    }
  }

  public getOcorrenciaFilter(): IStorageFilter | null {
    try {
      const data = sessionStorage.getItem("@portalCliente_OcorrenciaFilter");

      if (data) {
        const result: IStorageFilter = JSON.parse(data);
        return result;
      }

      return null;
    } catch (error) {
      throw new Error("getOcorrenciaFilter: " + error)
    }
  }



  public async setRelacaRondaFilter(dataInicial: string, dataFinal: string) {
    try {
      const data = {
        dataInicial,
        dataFinal,
      }
      sessionStorage.setItem("@portalCliente_RelacaRondaFilter", JSON.stringify(data));
    } catch (error) {
      throw new Error("setRelacaRondaFilter: " + error)
    }
  }

  public getRelacaRondaFilter(): IStorageFilter | null {
    try {
      const data = sessionStorage.getItem("@portalCliente_RelacaRondaFilter");

      if (data) {
        const result: IStorageFilter = JSON.parse(data);
        return result;
      }

      return null;
    } catch (error) {
      throw new Error("getRelacaRondaFilter: " + error)
    }
  }

}