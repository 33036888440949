import { useEffect, useRef, useState } from "react";
import { IDataNoticias } from "../../@interfaces";
import ApiNoticias from "../../services/ApiNoticias";

export default function useNoticias() {
  const [data, setData] = useState<IDataNoticias[]>([]);
  const [notAnymore, setNotAnymore] = useState(false);
  const page = useRef(1);
  const refNotAnymore = useRef(false);
  const [loading, setLoading] = useState<boolean>(false);


  async function getData(cliente: number, reset?: boolean) {
    try {
      if (!cliente) return;

      if (refNotAnymore.current && !reset) return;

      if (reset) {
        refNotAnymore.current = false;
        setNotAnymore(false);
        setData([]);
        page.current = 1;
      }

      setLoading(true);

      const response = await ApiNoticias.getNoticias(page.current, cliente);

      if (!response.error && (response.noticias && response.noticias.length > 0)) {

        setData(prev => [...prev, ...response.noticias!]);
        page.current++;
      } else {
        setNotAnymore(true);
        refNotAnymore.current = true;
        return;
      }

    } catch (error) {
      throw new Error(`getNoticias: ${error}`)
    } finally {
      setLoading(false)
    }
  }


  return { data, getData, loading, notAnymore, page }

}