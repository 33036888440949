import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { MdDownload, MdOpenInNew } from "react-icons/md";
import { useTheme } from "styled-components";
import DocFilter from "./docFilter";
import { CheckLabel, CheckOption, Container, DocList, DownloadSelectedFilesButton, FilterContainer, FilterMessage, LabelContainer, OptionsContainer } from "./styles";
import { IDocumento, ITipoDocumento } from "../../@interfaces";
import { SelectOptions, TipoDocumentoOpcoes, Filtros } from "../../@types";
import ToastContext from "../../contexts/toast";
import { CustumerContext } from "../../contexts/custumer";
import APIFuncionario from "../../services/APIFuncionario";
import APIArquivos from "../../services/APIArquivos";
import aux from "../../utils/auxiliar";
import DocView from "../docview";
import Options from "../select/options";
import Loading from "../loading";
import APIDocumentos from "../../services/APIDocumentos";
import MensagemListaVazia from "../mensagemlistavazia";



interface IDocsProps {
  startDate: string;
  finalDate: string;
  employeeId?: number;
  local?: number;
}





const Docs: React.FC<IDocsProps> = ({ startDate, finalDate, employeeId, local }) => {
  const docFuncionario = !!employeeId && !!local;

  const filtrosValoresIniciais: Filtros = {
    dataInicial: docFuncionario ? startDate : '',
    dataFinal: finalDate,
    alocacao: 'tafTodos',
    assinatura: 'tacTodos',
    tipoDocumento: null,
    empresa: null,
    local: null
  }


  const [filtrosAtuais, setFiltrosAtuais] = useState<Filtros>();

  const { colors } = useTheme();
  const [docList, setDocList] = useState<IDocumento[]>();
  const [selectedDocs, setSelectedDocs] = useState<number[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<string | null>();

  const [docTypes, setDocTypes] = useState<TipoDocumentoOpcoes[]>();


  const [isDocTypeOptionsOpen, setIsDocTypeOptionsOpen] = useState<boolean>();

  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>();



  const [docToShow, setDocToShow] = useState<string>();
  const [isDocModalOpen, setDocModalOpen] = useState<boolean>(false);

  const [emptyListMessage, setEmptyListMessage] = useState<boolean>();

  const { showErrorToast } = useContext(ToastContext);
  const { id_current_custumer } = useContext(CustumerContext);



  const tiposFixos: TipoDocumentoOpcoes[] = [
    { value: 1, label: "FGTS", grupoFiltros: 'empresa' },
    { value: 2, label: "GPS", grupoFiltros: 'empresa' },
    { value: 3, label: "Dtcf Web", grupoFiltros: 'empresa' },
    { value: 4, label: "Protocolo FGTS", grupoFiltros: 'empresa' },
    { value: 5, label: "Folha de Pagamento", grupoFiltros: 'cliente' },
    { value: 6, label: "SEFIP", grupoFiltros: 'cliente' },
    { value: 7, label: "Darf", grupoFiltros: 'cliente' },
    { value: 8, label: "GPS Cliente", grupoFiltros: 'cliente' },
  ]


  useEffect(() => {
    if (docFuncionario) loadDocTypes();
    else setDocTypes(tiposFixos)
  }, [])


  useEffect(() => {
    const novosValores: Filtros = {...filtrosValoresIniciais, dataInicial: '', dataFinal: ''};
    
    setDocList([]);

    setFiltrosAtuais({...novosValores});
  }, [id_current_custumer]);



  useEffect(() => {
    if (!!docTypes && !!filtrosAtuais?.tipoDocumento) loadDocs();
  }, [filtrosAtuais])




  async function loadDocTypes() {
    try {
      const response = await APIFuncionario.getTiposDocumento();

      if (!response.error) {
        const formattedDocTypes = formatDocTypes(response.tiposDocumentos!);
        setDocTypes(formattedDocTypes);
      } else {

      }
    } catch {
    } finally {
      setLoading(false);
    }
  }




  function formatDocTypes(list: ITipoDocumento[]): SelectOptions[] {
    const formattedList = list.map(item => {
      return { value: item.tipo, label: item.nome }
    })

    return formattedList;
  }




  async function loadDocs() {

    try {
      // if(!docFuncionario && !docTypeValue) return;
      setEmptyListMessage(false);
      setLoading(true);

      setDocList([]);

      const { dataInicial, dataFinal, alocacao, assinatura, tipoDocumento } = filtrosAtuais!;

      const bodies = docFuncionario ? [
        {
          cartaoPonto: {
            funcionario: employeeId,
            dataInicio: moment(dataInicial).format("YYYYMMDD"),
            dataFinal: moment(dataFinal).format("YYYYMMDD"),
            cliente: id_current_custumer,
            local: local,
            tipoAlocacao: alocacao,
            tipoAssinatura: assinatura
          }
        },
        {
          demonstrativoPontoCliente: {
            funcionario: employeeId,
            dataInicio: moment(dataInicial).format("YYYYMMDD"),
            dataFinal: moment(dataFinal).format("YYYYMMDD"),
            cliente: id_current_custumer,
            local: local,
          }
        }
      ] : gerarBody();



      const response = docFuncionario ?
        await APIFuncionario.getDocumentos(bodies[tipoDocumento! - 1 as keyof typeof bodies]) :
        await APIDocumentos.getDocumentos(bodies);



      if (!response.error) {
        setDocList(response.documentos);
      } else {
        setEmptyListMessage(true);
      }

    } catch {
      showErrorToast("Erro ao carregar a lista de documentos")
    } finally {
      setLoading(false)
    }
  }



  function gerarBody(): any {
    // let tipo;
    let parametros;

    const { tipoDocumento, empresa, dataInicial, local } = filtrosAtuais!;
    const grupoTipo = tiposFixos.find(item => item.value === tipoDocumento)?.grupoFiltros;

    if (grupoTipo === 'empresa') {
      parametros = {
        empresa,
        anomes: moment(dataInicial).format("YYYYMM")
      }
    } else {
      parametros = {
        empresa,
        anomes: moment(dataInicial).format("YYYYMM"),
        local,
        cliente: id_current_custumer
      }
    }

    let label = tiposFixos.find(item => item.value === tipoDocumento)?.label.replace(" de ", "");
    label = label!.replace(" Cliente", "");
    label = label!.replace(/ /g, "");


    const tipoParam = grupoTipo! + label;

    return {
      [tipoParam]: parametros
    }
  }



  function handleCheck(id: number, checked: boolean) {
    if (checked) setSelectedDocs([...selectedDocs, id]);
    else setSelectedDocs(selectedDocs.filter(item => item !== id));

  }




  function handleSelectAllCheck(checked: boolean) {
    setSelectAllChecked(checked);
    if (checked) setSelectedDocs(docList!.map(item => item.arquivo));
    else setSelectedDocs([]);
  }




  async function loadFile(id: number) {
    try {

      const response = await APIArquivos.getArquivo({ Arquivos: [id] })

      if (!response.error) {
        return response.listaArquivo![0]!.conteudo;
      }

      return false;

    } catch {
      return false;
    }
  }




  async function openDoc(id: number) {
    setDocModalOpen(true);
    const file = await loadFile(id);

    if (file) setDocToShow(file);
    else showErrorToast('Erro ao abrir o documento.');
  }




  function closeDoc() {
    setDocModalOpen(false);
    setDocToShow('');
  }




  async function downloadFile(id: number) {
    setActionLoading(String(id))
    const file = await loadFile(id);

    const fileObj = docList?.find(item => item.arquivo === id);
    const name = fileObj?.descricaoArquivo;
    if (file) aux.downloadFile(file, `${name}.pdf`);
    else showErrorToast('Erro ao fazer o download do documento.');

    setActionLoading(null);
  }




  async function downloadSelectedFiles() {
    setSelectedDocs([]);
    setSelectAllChecked(false);

    for (const docId of selectedDocs) {
      await downloadFile(docId);
    }

  }




  function closeDocTypeOptions() {
    setIsDocTypeOptionsOpen(false);
  }



  function handleSelectDocType(value: number | string) {
  

    setFiltrosAtuais({...filtrosAtuais!, tipoDocumento: parseInt(String(value))})
  }





  function openFilterModal() {
    setFilterModalOpen(true);
  }




  function closeFilterModal() {
    setFilterModalOpen(false);
  }




  function handleApplyFilters(filters: Filtros) {
    setFiltrosAtuais({...filters});
  }


  const concantenarNome = tiposFixos.find(item => item.value === filtrosAtuais?.tipoDocumento)?.grupoFiltros === 'cliente';



  return (
    <Container>

      {isDocModalOpen && <DocView file={docToShow} closeView={closeDoc} />}

      {isDocTypeOptionsOpen && <Options close={closeDocTypeOptions} selectOption={handleSelectDocType} itens={docTypes} />}

      {isFilterModalOpen &&
        <DocFilter
          applyFilter={handleApplyFilters}
          handleClose={closeFilterModal}
          docFuncionario={docFuncionario}
          filters={filtrosAtuais!}
          docTypeOptions={docTypes!}
        />}


      <FilterContainer>
        {!!docTypes && <a onClick={openFilterModal}>

          <FaFilter color={colors.halfOpacityBlack} style={{ marginTop: 3 }} />
          <label>Filtros</label>

        </a>}
      </FilterContainer>

      {!isLoading && docList?.length! > 0 && <DocList>
        <li style={{ paddingBottom: 20, paddingTop: 20, background: selectAllChecked ? colors.halfOpacityBlack : '' }}>
          <CheckOption>
            <input
              type={"checkbox"}
              id="select_all"
              checked={selectAllChecked}
              onChange={event => handleSelectAllCheck(event.target.checked)}
            />
            <CheckLabel
              htmlFor="select_all"
              style={{ color: selectAllChecked ? colors.white : colors.halfOpacityBlack }}
            >
              Selecionar todos
            </CheckLabel>
          </CheckOption>

          {selectedDocs.length > 0 && <DownloadSelectedFilesButton onClick={downloadSelectedFiles}>
            <BsFillArrowDownCircleFill size={16} color={selectAllChecked ? colors.white : colors.halfOpacityBlack} style={{ marginBottom: 2 }} />
            <span style={{ color: selectAllChecked ? colors.white : colors.halfOpacityBlack }}>Baixar documentos selecionados</span>
          </DownloadSelectedFilesButton>}

        </li>

        {docList!.map(item => (
          <li key={item.arquivo}>
            <CheckOption>
              <input
                type={"checkbox"}
                id={String(item.arquivo)}
                checked={selectedDocs?.includes(item.arquivo)}
                onChange={event => handleCheck(item.arquivo, event.target.checked)}
                onClick={e => e.stopPropagation()}
              />
              <LabelContainer >
                <CheckLabel htmlFor={String(item.arquivo)}><a>{item.descricaoArquivo} {concantenarNome && " - " + item.nomeLocal}</a></CheckLabel>
              </LabelContainer>
            </CheckOption>

            <OptionsContainer>
              {actionLoading === String(item.arquivo) ?
                <Loading width={20} noPadding compact /> :
                <a onClick={e => downloadFile(item.arquivo)}>
                  <MdDownload
                    color={colors.halfOpacityBlack}
                    size={20}
                  />
                </a>
              }
              <a onClick={() => openDoc(item.arquivo)}>
                <MdOpenInNew
                  color={colors.halfOpacityBlack}
                  size={20}
                />
              </a>
            </OptionsContainer>
          </li>
        ))}

      </DocList>}


      {(!docList || docList?.length === 0) && !isLoading &&
        <MensagemListaVazia
          texto={'Filtre para buscar documentos'}
          textoSecundario={'Não há dados a serem mostrados'}
        />}



      {isLoading && <Loading />}

    </Container>
  )
}

export default Docs;
