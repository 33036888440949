import React, { useContext, useEffect, useRef, useState } from 'react';
import CardFeed from './cardfeed';
import { Container, Overlay, FeedContainer, LoadingContainer, NoData } from './styles';

import useNoticias from './useNoticias';
import Loader from 'react-loader-spinner';
import { useTheme } from "styled-components";
import Skeleton from "./cardfeed/skeleton"
import { CustumerContext } from '../../contexts/custumer';

const Noticias: React.FC = () => {
  const { data, getData, loading, notAnymore, page } = useNoticias();
  const theme = useTheme();
  const refAux = useRef<HTMLDivElement>(null);
  const { id_current_custumer } = useContext(CustumerContext);
  const refCustumer = useRef<number>(0);

  useEffect(() => {
    if (refCustumer.current > 0 && (id_current_custumer !== refCustumer.current)) {
      refCustumer.current = id_current_custumer;
      getData(id_current_custumer, true);
      return;
    }
    if (id_current_custumer) refCustumer.current = id_current_custumer;
  }, [id_current_custumer])



  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        getData(refCustumer.current);
      }
    })

    if (refAux.current) {
      observer.observe(refAux.current);
    }

    return () => {
      if (refAux.current) {
        observer.unobserve(refAux.current);
      }
    }

  }, []);






  return (
    <Container>
      <Overlay />
      <FeedContainer>
        {loading && page.current === 1 && <Skeleton />}
        {
          data?.map((item, index) => {
            return (
              <CardFeed
                imageBase64={item?.images}
                title={item!.title}
                dateCreated={item!.date}
                body={item?.body}
                pdfs={item?.pdfs}
                key={index}
              />
            )
          })
        }
        <div ref={refAux} />
        {!notAnymore && (
          <LoadingContainer opacity={(loading && page.current !== 1) ? 1 : 0}>
            <Loader type="TailSpin" color={theme.colors.primary} height={30} />
          </LoadingContainer>
        )}
        {notAnymore && <NoData>Sem mais postagens para demonstrar</NoData>}
      </FeedContainer>
      <Overlay />
    </Container>
  )
}

export default Noticias;