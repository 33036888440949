import styled from "styled-components";



export const Container = styled.div` 
  flex: 1;
  background: ${props => props.theme.colors.white};
  padding: 20px 50px;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
`

export const PhotoContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 75px;
  overflow: hidden;
`

export const Photo = styled.img`
  width: 100%;
  height: 100%;
`

export const HeaderInfo = styled.div`
  color: ${props => props.theme.colors.halfOpacityBlack};
  h1 {
    font-size: 1rem;
    margin-bottom: 5px;
  }

  h2 {
    font-size: .75rem;
    font-weight: normal;
  }
`


export const ErrorMessage = styled.span`
  font-size: .857rem;
  color: ${props => props.theme.colors.halfOpacityBlack};
  align-self: center;
  margin: 20px 0 5px;
`


export const ReloadLink = styled.a`
  color: ${props => props.theme.colors.blue};
  align-self: center;
  font-size: .857rem;

  :hover{
    cursor: pointer;
  }
`