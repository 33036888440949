import { Container, Options } from "./style"
import { ISelectGroupProps } from "../../@interfaces";
import Title from "../title";
import Loading from "../loading";


const SelectionGroup: React.FC<ISelectGroupProps> = ({ options, onSelect, precheckedItems, error, isLoading }) => {
  return (
    <Container>
      <Title>Selecione os clientes</Title>
      <Options>
        {!isLoading && options.map((item, index) => (
          <>
            <div>
              <input
                type={'checkbox'}
                id={item.value.toString()}
                defaultChecked={precheckedItems?.includes(parseInt(String(item.value)))}
                onChange={event => onSelect(item.value, event.target.checked)}
              />
              <label htmlFor={item.value.toString()}>{item.label}</label>
            </div>
          </>
        ))}
      </Options>

      {isLoading && <Loading />}
      {error && <p>Escolha pelo menos um cliente.</p>}

    </Container>
  )
}

export default SelectionGroup;