import styled from 'styled-components'

interface IPropsLoadingContainer {
  opacity: 0 | 1;
}

export const Container = styled.div`
  background: ${props => props.theme.colors.silverBG};
  display: grid;
  grid-template-columns: auto 65% auto;
  align-items: center;
  /* width: max-contents ;  */
`

export const FeedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`


export const Overlay = styled.div`
  width: 100%;
  padding: 10px;
`

export const LoadingContainer = styled.div<IPropsLoadingContainer>`
  width: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  opacity: ${props => props.opacity};
 `

export const NoData = styled.span`
  font-size: 14px;
  color: #999;
  align-self: center;
  &:before{
    display: block;
    content: '';
    width: 100px;
    height: 1px;
    background: #999;
    margin: 6px auto;        
    opacity: .3;
  }
 `


