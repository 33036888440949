import axios from "./APIAxios";
import storage from "../storage";
import { ICompaniesResponse, ILocalBody, ILocalResponse, IPontoBody, IPontoResponse, IVaga } from "../@interfaces";
import Api from "./APIAxios";


const _MOCK = [
	{
		turno: 'Manhã',
		cargo: 'Vigilante',
		entrada: '8h00',
		saidaRefeicao: '12h00',
		retornoRefeicao: '13h00',
		saida: '17h00',
		registroMarcacao: [
			{
				re: 2290,
				nome: 'João Aparecido',
				entrada: '8h00',
				saidaRefeicao: '12h00',
				retornoRefeicao: '13h00',
				saida: '17h00'
			},
			{
				re: 1256,
				nome: 'Maria Silva',
				entrada: '8h00',
				saidaRefeicao: '12h00',
				retornoRefeicao: '13h00',
				saida: '17h00'
			},
			{
				re: 9845,
				nome: 'Maurício Fonseca',
				entrada: '8h00',
				saidaRefeicao: '12h00',
				retornoRefeicao: '13h00',
				saida: '17h00'
			},
			{
				re: 2290,
				nome: 'Augusto Silva',
				entrada: '8h00',
				saidaRefeicao: '12h00',
				retornoRefeicao: '13h00',
				saida: '17h00'
			},

		]
	},
	{
		turno: 'Manhã',
		cargo: 'Vigilante Líder',
		entrada: '7h00',
		saidaRefeicao: '12h00',
		retornoRefeicao: '13h00',
		saida: '17h00',
		registroMarcacao: [
			{
				re: 2290,
				nome: 'Alan Machado',
				entrada: '8h00',
				saidaRefeicao: '12h00',
				retornoRefeicao: '13h00',
				saida: '17h00'
			},



		]
	}
]

const _PLACELIST = [
	{
		local: 1,
		descricao: "Local A"
	},
	{
		local: 2,
		descricao: "Local B"
	},
	{
		local: 3,
		descricao: "Local C"
	}
]


const APIPonto = {
	getPontoList: async function (body: IPontoBody): Promise<IPontoResponse> {
		const url = storage.getClientUrl();
		try {

			const response = await Api.post<IPontoResponse>(`${url}/RegistroMarcacao`, body);
			// {
			// 	data: {
			// 		status: 0,
			// 		vagas: _MOCK,
			// 		mensagem: "Sucesso"
			// 	}
			// }

			if (response.data.status !== 0) {
				return { error: true, message: response.data.mensagem }
			} else {
				return response.data;
			}



		} catch {
			return { error: true, message: 'Algo deu errado' };

		}

	},

	getPlacesList: async function (body: ILocalBody): Promise<ILocalResponse> {
		const url = storage.getClientUrl();
		try {
			const response = await Api.post<ILocalResponse>(`${url}/ListaLocal`, body);
			if (response.data.status !== 0) {
				return { error: true, message: response.data.mensagem }
			} else {
				return response.data;
			}

		} catch (e) {
			console.log(e);
			return { error: true, message: 'Algo deu errado' };
		}
	},

	getCompanies: async function (): Promise<ICompaniesResponse> {
		const url = storage.getClientUrl();
		try {
			const response = await Api.get<ICompaniesResponse>(`${url}/ListaEmpresas`);

			if ((response.data.status !== 0) && (response.data.status !== 127)) {
				return { error: true, message: response.data.mensagem }
			} else {
				return response.data;
			}

		} catch (e) {
			console.log(e);
			return { error: true, message: 'Algo deu errado' };
		}
	},


}


export default APIPonto;