import { Container, UserList, NewUserButton, RowContainer, TableContainer, BodyContainer } from './styles';
import { useContext, useEffect, useState } from "react";
import APIUser from '../../services/APIUser';
import UserForm from './userform';
import Loading from "../../components/loading";

import ConfirmationModal from "../../components/confirmationmodal";
import UserCard from "./usercard";
import ToastContext from "../../contexts/toast";

import { User } from "../../@types";

import BoxContainer from '../../components/boxcontainer';
import Table from '../../components/table';
import AuthContext from '../../contexts/auth';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { IoMdPersonAdd } from 'react-icons/io';
import { FaExchangeAlt } from 'react-icons/fa';
import ChangePass from '../../components/passwordchangemodal';
import Button from '../../components/button';
import { useTheme } from 'styled-components';


const AccessControl: React.FC = () => {

  const tableColumns = [
    {
      fieldName: 'Nome'
    },
    {
      fieldName: 'E-mail'
    },
    {
      fieldName: 'Telefone'
    },
    {
      fieldName: 'Ramal'
    },
    {
      fieldName: 'Master'
    },
  ]

  const [userList, setUserList] = useState<User[]>([]);
  const [userListToShow, setUserListToShow] = useState<any[]>([]);
  const [isUserFormVisible, setIsUserFormVisible] = useState<boolean>(false);
  const [userToUpdate, setUserToUpdate] = useState<User | null>();
  const [userToDelete, setUserToDelete] = useState<string | number | null>(0);
  const [showChangePass, setShowChangePass] = useState<boolean>(false);
  const [isUserListLoading, setIsUserListLoading] = useState<boolean>(false);
  const { showErrorToast, showSuccessToast } = useContext(ToastContext);
  const { loggedEmail, isMasterUser } = useContext(AuthContext);
  const isMaster = isMasterUser();

  const theme = useTheme();

  useEffect(() => {
    loadUserList();
  }, []);

  async function loadUserList() {
    try {
      setIsUserListLoading(true);
      const response = await APIUser.getUserList();

      if (response.err) {
        return;
      }

      const _userList = response.map((item: any, index: any) => {
        return {
          ...item,
          id: index + 1
        }
      })

      setUserList(_userList);



      const _rows: any = _userList.map((item: User) => {

        let actions = [];

        if (isMaster) {
          actions.push({
            icon: <AiOutlineEdit size={16} />,
            indexAction: 0,
          })
        }


        if (isMaster) {
          if (item.email !== loggedEmail) {
            actions.push({
              icon: <AiOutlineDelete size={16} />,
              indexAction: 1,
            })
          }
        }

        return {
          "id": item.id,
          "Nome": item.nome,
          "E-mail": item.email,
          "Telefone": `${item.ddi} ${item.ddd} ${item.telefone}`,
          "Ramal": item.ramal,
          "Master": item.master ? "Sim" : "Não",
          actionButtons: actions
        }
      })

      setUserListToShow(_rows);

    } catch {
      return;
    } finally {
      setIsUserListLoading(false);
    }

    setUserToUpdate(null);
  }


  function openUserForm() {
    setIsUserFormVisible(true);
  }

  function closeUserForm() {
    setIsUserFormVisible(false);
    setUserToUpdate(null)
  }


  function handleSetUserToDelete(id: any) {
    const aux = userList.filter(i => i.id === id)[0];
    setUserToDelete(aux.codigo);
  }

  function handleCancelDelete() {
    setUserToDelete(null)
  }



  function handleSetUserToUpdate(id: any) {
    const user = userList.filter(i => i.id === id)[0];
    setUserToUpdate(user);
    setIsUserFormVisible(true);
  }


  async function handleDeleteUser(id: string | number) {
    try {
      const response = await APIUser.deleteUser(id);

      if (response.err) {
        showErrorToast(response.message);
        return;
      }

      showSuccessToast(response.message);
      setUserToDelete(null)
      loadUserList();

    } catch {
      return;
    }
  }




  function openChangePass() {
    setShowChangePass(true);
  }

  function closeChangePass() {
    setShowChangePass(false);
  }



  return (
    <>
      {showChangePass && <ChangePass close={closeChangePass} />}
      {userToDelete ?
        <ConfirmationModal
          closeModal={handleCancelDelete}
          onActionClick={() => handleDeleteUser(userToDelete)}
          message='Realmente deseja excluir esse usuário?'
        /> : null}

      {isUserFormVisible &&
        <UserForm
          userToUpdate={userToUpdate}
          reloadList={loadUserList}
          closeModal={closeUserForm}
        />
      }

      <Container>
        <BoxContainer title='Usuários'>

          <BodyContainer>
            <RowContainer>
              {isMaster && <NewUserButton onClick={openUserForm}>
                <IoMdPersonAdd size={15} />
                Novo usuário
              </NewUserButton>}

              <NewUserButton onClick={openChangePass} colorButton={theme.colors.darkSilver}>
                <FaExchangeAlt size={15} />
                Trocar minha senha
              </NewUserButton>
            </RowContainer>

            <UserList>
              {userList?.map(item => (
                <UserCard
                  user={item}
                  handleSetUserToDelete={handleSetUserToDelete}
                  handleSetUserToUpdate={handleSetUserToUpdate}
                />
              ))}
            </UserList>

            {isUserListLoading ?
              <Loading /> :
              <TableContainer>
                <Table
                  columns={tableColumns}
                  rows={userListToShow}
                  actions={[handleSetUserToUpdate, handleSetUserToDelete]}
                  refAction={"id"}
                />
              </TableContainer>
            }
            </BodyContainer>
        </BoxContainer>
      </Container>
    </>
  )
}



export default AccessControl;