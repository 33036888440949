const storage = {
  setLicenseToken: function (token: string) {
    try {
      sessionStorage.setItem('@portal:license_token', token);
    } catch {

    }
  },

  getLicenseToken: function () {
    try {
      return sessionStorage.getItem('@portal:license_token');
    } catch {

    }
  },

  setClientUrl: function (url: string) {
    try {
      sessionStorage.setItem('@portal:client_url', url);
    } catch {

    }
  },

  getClientUrl: function () {
    try {
      return sessionStorage.getItem('@portal:client_url');
    } catch (e) {
      throw new Error("getClientUrl: " + e)
    }
  },

  setLoggedUser: function (user: string) {
    try {
      sessionStorage.setItem('@portal:logged_user', user);
    } catch {

    }
  },

  getLoggedUser: function () {
    try {
      return sessionStorage.getItem('@portal:logged_user');
    } catch {
      return '';
    }
  },


  //Por enquanto está any pois não sabemos a estrutura ainda
  setCustumers: function (custumers: any) {
    try {
      sessionStorage.setItem('@portal:custumers', JSON.stringify(custumers));
    } catch (e) {
      throw new Error("setCustumers: " + e)
    }
  },

  getCustumers: function () {
    try {
      const custumersStr = sessionStorage.getItem('@portal:custumers');
      if (custumersStr) {
        return JSON.parse(custumersStr);
      } else {
        return [];
      }
    } catch (e) {
      throw new Error("getCustumers: " + e)
    }
  },

  setAccessToken: function (token: string) {
    try {
      sessionStorage.setItem('@portal:access_token', token);
    } catch (e) {
      throw new Error("setAccessToken: " + e)
    }
  },

  getAccessToken: function () {
    try {
      return sessionStorage.getItem('@portal:access_token');
    } catch (e) {
      throw new Error("getAccessToken: " + e)
    }
  },


  setGroupLogged: function (token: string) {
    try {
      sessionStorage.setItem('@portal:grupologged', token);
    } catch (e) {
      throw new Error("setGroupLogged: " + e)
    }
  },

  getGroupLogged: function () {
    try {
      return sessionStorage.getItem('@portal:grupologged');
    } catch (e) {
      throw new Error("getGroupLogged: " + e)
    }
  },


  setLogoGroupLogged: function (logo: string) {
    try {
      sessionStorage.setItem('@portal:logogroup', logo);
    } catch (e) {
      throw new Error("setGroupLogged: " + e)
    }
  },

  getLogoGroupLogged: function () {
    try {
      return sessionStorage.getItem('@portal:logogroup');
    } catch (e) {
      throw new Error("getGroupLogged: " + e)
    }
  },

  setSelectedClient: function (client: string) {
    try {
      sessionStorage.setItem('@portal:selectedclient', client);
    } catch (e) {
      throw new Error("selectedclient: " + e)
    }
  },

  getSelectedClient: function() {
    try {
      return sessionStorage.getItem('@portal:selectedclient');
    } catch (e) {
      throw new Error("getSelectedClient: " + e)
    }
  },


  setCurrentPage: function (page: string) {
    try {
      sessionStorage.setItem('@portal:currentpage', page);
    } catch (e) {
      throw new Error("currentpage: " + e)
    }
  },


  getCurrentPage: function (){
    try {
      return sessionStorage.getItem('@portal:currentpage');
    } catch (e) {
      throw new Error("currentpage: " + e)
    }
  },

  clearAll: function () {
    try {
      sessionStorage.clear();
    } catch {
      return '';
    }
  },
}

export default storage;