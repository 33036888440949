import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`


export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: #fafafa;
  padding: 20px;
  gap: 20px;

  > a, label {
    display: flex;
    align-items: center;
    gap: 20px;
    color: ${props => props.theme.colors.halfOpacityBlack};
    font-family: ${props => props.theme.font};
    font-size:.875rem;
    font-weight: 500;

    :hover {
      cursor: pointer;
    }
    
  }
`


export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  span {
    font-size: .75rem;
    text-align: center;
  }
`


export const Input = styled.input`
  border: none;
  border-radius: 8px;
  background: #fff;
  padding: 7px 15px;
  width: 175px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  outline: none;
  color: rgba(0,0,0,.5);
  font-size: .75rem;
`


export const DocList = styled.ul`
  margin-top: 20px;
  max-height: 50vh;
  overflow-y: auto;

  li {
    display: flex;
    padding: 6px 20px;
    justify-content: space-between;
    align-items: center;

    :hover{
      background: ${props => props.theme.colors.lightGray};
    }
  }
`

export const CheckOption = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;

  
`

export const DownloadSelectedFilesButton = styled.button`
  border: none;
  background: none;
  display: flex;
  gap: 15px;
  align-items: center;

  :hover{
    cursor: pointer;
  }

  span {
    font-size: 1rem; 
    font-weight: bold;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  span {
    color: ${props => props.theme.colors.black};
    font-size: .875rem;
  }

`

export const CheckLabel = styled.label`
  font-weight: bold;
  font-size: 1rem;
  color: ${props => props.theme.colors.halfOpacityBlack};

  
`

export const OptionsContainer = styled.div`
  display: flex;
  gap: 10px;

  a {
    :hover {
      cursor: pointer;
    }
  }
`


export const FilterMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  color: ${props => props.theme.colors.black};
  font-size: .875rem;
  align-self: center;
  letter-spacing: 1px;
  margin-top: 20px;
  font-weight: bold;

  span:last-child {
    font-weight: 300;
  }
`

interface IDropdownProps{
  width?: number;
}



const inputStyle = css`
	border: none;
	border-radius: 8px;
	background: #fff;
	padding: 7px 15px;
	width: 145px;
	display: flex;
	justify-content: space-between;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	outline: none;
	color: rgba(0,0,0,.5);
`



export const DropDown = styled.div<IDropdownProps>`
  ${inputStyle}
  ${props => props.width? `width: ${props.width}px;`:''}
  
  :hover{
		cursor: pointer;
	}

  display: flex;
  align-items: center;

	span{
		color: rgba(0,0,0,.5);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
    font-size: .75rem;
	}
`