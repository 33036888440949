import React, { useEffect, useMemo, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {
  IProps
} from "./@types"

import LightBoxModal from "./modal"

import {
  MediaBox,
} from './styles';




const LightBoxGallery: React.FC<IProps> = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [currentImage, setCurrentImage] = useState(0);



  useEffect(() => {
    setImages(data)
    return () => setImages([])
  }, [data]);




  function show(index: number) {
    setCurrentImage(index)
    setShowModal(true)
  }

  function hidden() {
    setShowModal(false);
  }


  function getNextIndex(type: string) {
    if (!images) return;

    let next = 0;

    if (type === "next") {
      next = (currentImage + 1) <= images.length - 1 ? (currentImage + 1) : (images.length - 1)
    } else if (type === "previous") {
      next = (currentImage - 1) >= 0 ? (currentImage - 1) : 0
    }

    setCurrentImage(next);
  }


  const imagesToShow = useMemo(() => {
    return images?.map((i, index) => {
      return (
        <div onClick={() => show(index)} key={index}>
          <LazyLoadImage
            src={i.src}
            width={80}
            height={80}
            alt="Imagem da ocorrência"
            effect='blur'
          />
        </div>
      )
    })
  }, [images]);


  function next() {
    getNextIndex("next");
  }

  function previous() {
    getNextIndex("previous");
  }


  return (
    <>
      {imagesToShow.length > 0 && <LightBoxModal
        show={showModal}
        close={hidden}
        imageSrc={images[currentImage].src}
        nextFunc={next}
        previousFunc={previous}
      />
      }
      <MediaBox>
        {imagesToShow}
      </MediaBox>
    </>
  )
}

export default LightBoxGallery;