import { useContext, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { IFuncionarioDetalhe } from "../../../@interfaces";
import MenuContext from "../../../contexts/menu";
import APIFuncionario from "../../../services/APIFuncionario";
import CloseButton from "../../../components/closebutton";
import EmptyListMessage from "../../../components/mensagemlistavazia";
import Loading from "../../../components/loading";
import Tab from "../../../components/tab";
import Geral from "./geral";
import { Container, Header, HeaderInfo, Photo, PhotoContainer, ReloadLink } from "./styles";
import Docs from "../../../components/docs";



interface IEmployeeDetailProps {
  startDate: string;
  finalDate: string;
  close: () => void;
  employeeId: number;
  local: number;
  employeePhoto?: string;
}


const EmployeeDetail: React.FC<IEmployeeDetailProps> = ({ startDate, finalDate, close, employeeId, employeePhoto, local }) => {
  const { colors } = useTheme();
  const [employee, setEmployee] = useState<IFuncionarioDetalhe>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showReloadLink, setShowReloadLink] = useState<boolean>();



  useEffect(() => {
    loadEmployee();
  }, []);



  async function loadEmployee() {
    try {
      setError('');
      setShowReloadLink(false);
      setLoading(true);
      const response = await APIFuncionario.getFuncionario({ funcionario: employeeId });
      if (!response.error) {
        setEmployee(response.funcionario);
      } else {
        setError(response.message);
        setShowReloadLink(true);

      }

    } catch (e) {
      setError("Erro ao carregar os dados.");
      setShowReloadLink(true);
    } finally {
      setLoading(false);
    }
  }





  return (
    <Container>
      <Header>
        {!!employee && <>
          <PhotoContainer>
            <Photo src={employeePhoto || "assets/user.png"} />
          </PhotoContainer>
          <HeaderInfo>
            <h1>{employee.nomeFuncionario}</h1>
            <h2>{employee.cargo}</h2>
          </HeaderInfo>
        </>
        }

        <CloseButton
          handleClose={close}
          styles={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 0
          }}
          size={20}
          color={colors.halfOpacityBlack}
        />

      </Header>

      {!!employee && !isLoading &&
        <Tab
          tabs={[
            {
              name: "Geral",
              component: <Geral data={employee} />
            },
            {
              name: "Documentos",
              component: <Docs startDate={startDate} finalDate={finalDate} employeeId={employeeId} local={local} />
            }
          ]}
        />
      }

      {isLoading && <Loading />}

      {!!error && <EmptyListMessage texto={error} />}
      {showReloadLink && <ReloadLink onClick={loadEmployee}>Tente novamente. {employeeId}</ReloadLink>}
    </Container>
  )
}


export default EmployeeDetail;