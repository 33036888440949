import React from "react";

import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";


import Login from "../pages/login";
import PasswordChange from "../pages/login/passwordchange";


const Rotas: React.FC = () => {
  return (
    <Switch>
      <Route path={"/"} exact component={Login} />
      <Route path={"/trocadesenha/:userinfo"} component={PasswordChange} />
      <Route path={"*"}>
        <Redirect to="/" />
      </Route>
    </Switch>
  )
}

export default Rotas;