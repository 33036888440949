import styled, { keyframes } from "styled-components"

const fadeOut = keyframes`
  from{
    opacity: 0
  }to{
    opacity: 1
  }
`

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: rgba(0,0,0,.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  animation ${fadeOut} .3s forwards
`





export const ImgContainer = styled.div`
  width: 70%;
  display: flex;
  cursor: pointer;
  justify-content: center;
  >img{
    max-width: 100%;
    max-height: calc(100vh - 80px);
    align-self: center;
  }
`

export const Header = styled.div`
  background: #000;
  width: 100%;
  padding: 10px 80px 10px;
  display: flex;
  align-items: center;
  
  justify-content: flex-end;  
  margin-bottom: 10px;
  gap: 20px;
  >button{
    border:none;
    background: none;
    color: #fff;
    cursor: pointer;
  }
`
