import styled from "styled-components"

//rgb(133, 133, 133) disable

import { IInputProps, IInputContainerProps } from "../../@interfaces";


export const InputComponent = styled.input<IInputProps>`
  width: 100%;
  font-size: .875rem;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  background: none;
  transition: all .3s;
  border-color: rgb(118, 118, 118);
  font-weight: 100;
  padding: 6px;
  ${props => props.hasBackground && `
    border: 1px solid ${props.colorOfBorder};
    background: #fff;
    
  `}
  &:focus{
    box-shadow: 0 0 0 0;
    outline: 0;
    border-color: ${props => props.theme.colors.primary};
  }
`

export const InputContainer = styled.div<IInputContainerProps>`
  padding: 5px 0;
 
  transition: all .7s;
  width: 100%;  
  border-bottom: ${({ hasBackground }) => hasBackground ? 'none' : `1px solid rgb(118, 118, 118);`};  
  color: rgb(118, 118, 118);
  &:focus-within{
    border-color: ${props => props.colorOfBorder ? props.colorOfBorder : props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }
  overflow: hidden;
  position:relative;
  >svg{
    position: absolute;
    right: 0;  
  }

  ${({ GAPVertical }) => {
    if (GAPVertical) {
      return `margin: ${GAPVertical}px 0;`
    }
  }}


${({ colorOfBorder }) => {
    if (colorOfBorder) {
      return `border-color: ${colorOfBorder}`
    }
  }}

`