import styled, { keyframes } from "styled-components";
import Button from '../button';
import { AiOutlineClose } from 'react-icons/ai';
import { IButtonStyleProps } from "../../@interfaces";

const slideUp = keyframes`
  from{
    opacity: 0;
    transform: translateY(-50px);
  }to{
    opacity: 1;
    transform: translateY(0);
  }
  
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContainer = styled.div`
  width: 350px;
  animation: ${slideUp} .7s forwards;
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  padding: 10px;
  >p{
    font-size: .875rem;
    text-align: center;
    line-height: 22px;
  }
`

export const CloseIcon = styled(AiOutlineClose)`
  align-self: flex-end;

  :hover{
    cursor: pointer;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`


export const OptionButton = styled(Button) <IButtonStyleProps>`
  border-radius: 0;
  width: 100px;

  ${props => props.outline && `
    background: ${props.theme.colors.white};
    color: ${props.theme.colors.primary};
    border: 1px solid ${props.theme.colors.primary};
  `}
`

