import React, { useState } from 'react';


import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import { useTheme } from 'styled-components';
import CloseButton from '../../closebutton';


import {
  Container,
  Content,
  ControllButtonLeft,
  ControllButtonRight,
  Header,
} from './styles';


const colors = {
  disabled: "rgba(255, 255, 255, .5)",
  enabled: "#fff"
}

interface IPropsComponent {
  close: () => void;
  show?: boolean;
  imageSrc: string;
  previousFunc: () => void;
  nextFunc: () => void;
}


const LightBoxGalleryModal: React.FC<IPropsComponent> = ({ close, show, imageSrc, previousFunc, nextFunc }) => {
  const [effect, setEffect] = useState<null | string>(null);
  const theme = useTheme();
  
  function next() {
    setEffect("next");
    nextFunc();
    setTimeout(() => {
      setEffect(null)
    }, 300)
  }


  function previous() {
    setEffect("previous");
    previousFunc();
    setTimeout(() => {
      setEffect(null)
    }, 300)
  }

  if (!show) return null;

  return (
    <Container>
      <Header>
        <CloseButton handleClose={close} size={22} color={theme.colors.white} />
      </Header>
      <ControllButtonLeft onClick={previous}>
        <FiArrowLeft size={22} color={theme.colors.white} />
      </ControllButtonLeft>
      <ControllButtonRight onClick={next}>
        <FiArrowRight size={22} color={theme.colors.white} />
      </ControllButtonRight>
      <Content className={effect ? effect : ""}>
        <img src={imageSrc} alt="anexo" />
      </Content>
    </Container>
  )
}

export default LightBoxGalleryModal;