import styled from "styled-components";

import { ICheckBoxStyleProps } from "../../@interfaces";

export const InputBox = styled.input<ICheckBoxStyleProps>`
  ${({ gapVertical }) => gapVertical && `margin: ${gapVertical}px 0;`}
  margin-right: 10px;
`

export const Container = styled.div<ICheckBoxStyleProps>`
 ${({ gapVertical }) => gapVertical && `margin: ${gapVertical}px 0;`}
  display: flex;
  align-items: center;
  >label{
    font-size: .875rem;
  }
  ${({ margin }) => margin && `margin: ${margin}`}
`