import React, { createContext, useEffect, useState } from 'react';

import { ICustumers } from "../@interfaces"
import { SelectOptions } from "../@types"
import storage from '../storage';


interface IContext {
  id_current_custumer: number;
  current_custumer_description: string;
  custumer_list: any;
  setListCustumer: (listCustumers: ICustumers[]) => void;
  selectCurrentCustumer: (id: number | string) => void;
  deleteList: () => void;
}

export const CustumerContext = createContext({} as IContext)

const CustumerContextProvider: React.FC = ({ children }) => {
  const [id_current_custumer, setCurrentCustumer] = useState<number>(0);
  const [current_custumer_description, setCustumerDescription] = useState<string>("");
  const [custumer_list, setCustumerList] = useState<SelectOptions[]>([]);


  useEffect(() => {
    const current = storage.getCustumers();

    if (current && current.length > 0) {
      setListCustumer(current);
    }

    
  }, [])


  function setListCustumer(listCustumers: ICustumers[]) {

    const custumers = listCustumers.map((i) => {
      return { ...i, value: i.codigo, label: i.nome }
    })
    setCustumerList(custumers);
    const selectedClientId = storage.getSelectedClient();
      
    const index = !!selectedClientId ? 
    custumers.findIndex(i => i.value === parseInt(selectedClientId)) : 0
    
    setCurrentCustumer(custumers[index].value);
    setCustumerDescription(custumers[index].label);
    
    storage.setCustumers(listCustumers);
  }


  function deleteList() {
    setCurrentCustumer(0);
    setCustumerDescription("")
    storage.setCustumers([]);
  }


  function selectCurrentCustumer(id: number | string): void {
    const current = custumer_list.findIndex(i => i.value === id)
    if (current === -1) return;
    const auxId = parseInt(String(custumer_list[current].value));
    storage.setSelectedClient(String(id));
    setCurrentCustumer(auxId);
    setCustumerDescription(custumer_list[current].label)
  }


  return (
    <CustumerContext.Provider value={{
      id_current_custumer,
      current_custumer_description,
      custumer_list,
      setListCustumer: setListCustumer,
      selectCurrentCustumer,
      deleteList
    }}>
      {children}
    </CustumerContext.Provider>
  )
}

export default CustumerContextProvider;