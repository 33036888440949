import styled from "styled-components";


export const Container = styled.div`
`

export const QuadroHeader = styled.div`
  background: #eee;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
  padding: 12px;
  position: sticky;
  top: -17px;
  font-size: .9rem;
`


export const HeaderRotulo = styled.span`
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
`


export const HeaderValor = styled.span`
  :not(:last-child){
    :after{
      content: '|';
      color: ${props => props.theme.colors.primary};
      margin-left: 12px;
    }
  }
  
`

export const Tabela = styled.table`
  width: 100%;
  margin: 15px 0 20px;
  text-align: left;
  table-layout: fixed;
  font-size: .9rem;

  th{
    font-weight: 500;
  }

  tr {
    width: 100%:
  }

  th, td {
    padding: 10px 15px;
  }

  td{
    color: ${props => props.theme.colors.halfOpacityBlack};
  }


  th:first-child, td:first-child{
    text-align: center;
    padding-right: 10px;
  }


  
`

export const LinhaFiltros = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  padding: 20px 0;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`


export const LinhaSkeleton = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 4fr 4fr 2fr 4fr 4fr;
  gap: 4px;
  padding: 10px 0;
`


export const MensagemContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${props => props.theme.colors.halfOpacityBlack};
  padding: 30px 0 50px;

`
