import React, { createContext, useEffect, useState } from "react"
import storage from "../storage";
//@ts-ignore
import jwt_decode from "jwt-decode";
interface IContextProps {
  isLogged: boolean;
  loggedEmail: string | null;
  signIn: (email: string) => void;
  signOut: () => void;
  isMasterUser: () => boolean;
  setIsPassExpired: (param: boolean) => void;
  isPassExpired?: boolean;
}


const AuthContext = createContext<IContextProps>({} as IContextProps);

export const AuthContextProvider: React.FC = ({ children }) => {
  const [isLogged, setIsLogged] = useState<boolean>(!!storage.getLoggedUser());
  const [loggedEmail, setLoggedEmail] = useState<string | null>(storage.getLoggedUser());
  const [isPassExpired, setIsPassExpired] = useState<boolean>(false);

  useEffect(() => {
    const logged = storage.getLoggedUser();
    if (logged) {
      setIsLogged(true);
    }
  }, [])


  function signIn(email: string) {
    setIsPassExpired(false);
    storage.setLoggedUser(email);
    setLoggedEmail(email);
    setIsLogged(true);
  }

  function signOut() {
    storage.clearAll();
    setIsLogged(false);
    if (isPassExpired) {
      setIsPassExpired(true);
    }
  }


  function isMasterUser() {
    const token = storage.getAccessToken();
    const { hkum } = jwt_decode(token);
    return hkum;
  }


  return (
    <AuthContext.Provider
      value={{
        isLogged,
        loggedEmail,
        signIn,
        signOut,
        isPassExpired,
        setIsPassExpired,
        isMasterUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;