import React, { CSSProperties } from 'react';

import { Label } from './styles';

interface IProps {
  style?: CSSProperties;
}

const LabelEmptyData: React.FC<IProps> = ({ style, children }) => {
  return (
    <Label style={style}>
      {children}
    </Label>
  )
}

export default LabelEmptyData;