import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
`

interface IOptionProps {
  selected?: boolean
}


export const PageIndicator = styled.div`
  padding: 10px;
  background: #eee;
  border-radius: 10px;
  display: flex;
  align-items: center;

  span {
    font-size: .75rem;
    letter-spacing: 1px;
    color: #2991D6;
  }
`


export const PageNavigator = styled.div`
  padding: 10px;
  background: #eee;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
`

export const NavButton = styled.button`
  border:none;
  width: 25px;
  height: 25px;
  background: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ disabled }) => disabled && `background: rgba(0,0,0,.2);`}
    
`

