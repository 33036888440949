import React, { useEffect } from "react";

import {
  Container,

} from "./styles"


import Ocorrencias from "./ocorrencia"
import RelacaoRondas from "./relacaorondas"
import ListProgramacoes from "./listprogramacoes"
import Eficiencia from "./eficiencia"



const Rondas: React.FC = () => {


  return (
    <Container>
      <RelacaoRondas />
      <Ocorrencias />


      <ListProgramacoes />
      <Eficiencia />
    </Container>
  )
}

export default Rondas;