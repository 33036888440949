import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  padding: 8px;  
  display: flex;
  justify-content: space-between; 
  cursor: pointer;
  font-family: ${props => props.theme.font};
  #empresa{
    color: ${props => props.theme.colors.greenyGray};
    margin-top: 4px;
    font-size: .75rem; 
    font-weight: 500;
  }

  #admissao{
    color: rgba(0,0,0,.4);
    font-weight: 500;
    font-size: .75rem;
  }

  #admissaoValue{
    color: rgba(0,0,0,.4);
  }

  &:hover{
    background: #eee
  }
`

export const UserContainer = styled.div`
  display: flex;   
  gap: 10px;
`

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  >span{    
    font-size: .875rem;
    margin-bottom: 6px;
  }
`

export const Avatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #D9D9D9;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  >img{
    max-width: 100%;   
    max-height: 100%;
  }
`