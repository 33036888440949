import styled from "styled-components";
import TitleComponent from "../../components/title"
import Button from "../../components/button";

interface ISection {
  marginTop?: number
}

interface Ibutton {
  changePass?: boolean;
}




export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh + 20px);
`

export const Section = styled.section<ISection>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ marginTop }) => {
    if (marginTop) {
      return `margin-top:${marginTop}px`
    }
  }}
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 7.5fr 2.5fr;
`


export const RowContainer = styled.div`
  width: 25%;
  height: 50px;
  display: flex;
  gap: 10px;
  @media(max-width: 700px){
    justify-content: space-between;
  }
`

export const Title = styled(TitleComponent)`
  font-size: ${props => props.theme.titleSize};
  font-weight: normal;
  margin-bottom: 20px;
`


export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`


export const TableContainer = styled.div`
  width: 100%;
  @media(max-width: 700px){
    display: none;    
  }
`


export const UserList = styled.ul`
  display: none;
  @media(max-width: 700px){
    display: flex;
    flex-direction: column;
  }
`

export const NewUserButton = styled(Button)<Ibutton>`
  font-size: .85rem;
`