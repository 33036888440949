//@ts-ignore

import React, { useContext } from 'react';
import { useTheme } from 'styled-components';
import CloseButton from "../../components/closebutton"
import Title from '../title';


import {
  Container,
  ContentBox,
  Header,
  Content,
  Footer,
  ActionButton,
  Separator,
  ContentFilters,
  RowSectionCSS
} from './styles';

interface IProps {
  handleClose: () => void;
  applyFilter: () => void;
  cleanFilter: () => void;
  width?: number;
  title?: string;
}

export const SeparatorFilter: React.FC = () => {
  return <Separator />
}

export const ContentFilter: React.FC = ({ children }) => {
  return <ContentFilters>
    {children}
  </ContentFilters>
}

export const RowSection: React.FC = ({ children }) => {
  return <RowSectionCSS>
    {children}
  </RowSectionCSS>
}

export const DATE_FORMAT = "YYYY-MM-DD"

const ModalFilters: React.FC<IProps> = ({ children, handleClose, applyFilter, cleanFilter, title, width }) => {
  const theme = useTheme();
  
  function closeModal(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target as Element;
    if (!target.className.split) return;
    const classLength = target.className.split(' ').length;
    if (target.className.split(' ')[classLength - 1] === 'modal-container') {
      handleClose();
    }
  }


  return (
    <Container onClick={closeModal} className={"modal-container"}>
      <ContentBox width={width}>
        <Header>
          <Title style={{ color: theme.colors.white }}>{title || "Filtros"}</Title>
          <CloseButton handleClose={handleClose} color={theme.colors.white} />
        </Header>
        <Content>
          {children}
        </Content>
        <Footer>
          <ActionButton
            color={"none"}
            colorLabel={theme.colors.pureBlack}
            noHover={true}
            onClick={cleanFilter}
            style={{ border: `1px solid ${theme.colors.lightGray}` }}
          >
            limpar filtro
          </ActionButton>
          <ActionButton onClick={applyFilter}>
            aplicar filtro
          </ActionButton>
        </Footer>
      </ContentBox>
    </Container>
  );
}

export default ModalFilters;
