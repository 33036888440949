import React from 'react';

import {
  IconClose,
  Button
} from './styles';


interface IProps {
  handleClose: () => void;
  color?: string;
  size?: number;
  styles?: React.CSSProperties
}

const CloseButton: React.FC<IProps> = ({ handleClose, size, color, styles = {} }) => {
  return (
    <Button onClick={handleClose} style={styles}>
      <IconClose size={size} color={color} />
    </Button>
  );
}

export default CloseButton;