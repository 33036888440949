import storage from "../storage";
import axios from "axios";
import Api from "./APIAxios";
import {
  IRelacaoRondasBody,
  IRelacaoRondaResponse,
  IBodyRelacaoRelacaoRondaProgramadaConcluida,
  IResponseRelacaoRondaProgramadaConcluida,
  IOcorrenciaResponse,
  IBodyOcorrencia,
  IBodyAnexoOcorrencias,
  IProgramacaoRondasResponse,
  IBodyProgramacaoRonda,
  IAnexosOcorrenciaResponse
} from "../@interfaces"

const APIRonda =
{
  getRelacaoRondas: async function (body: IRelacaoRondasBody): Promise<IRelacaoRondaResponse> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IRelacaoRondaResponse>(`${url}/RelacaoRondas`, body);

      if (response?.data?.status === 0)
        return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },

  getRelacaoRondaProgramadaConcluida: async function (body: IBodyRelacaoRelacaoRondaProgramadaConcluida): Promise<IResponseRelacaoRondaProgramadaConcluida> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IResponseRelacaoRondaProgramadaConcluida>(`${url}/relacaoRondaProgramadaConcluida`, body);

      if (response?.data?.status === 0)
        return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {

      return { error: true, message: error };
    }
  },

  getOcorrencias: async function (body: IBodyOcorrencia): Promise<IOcorrenciaResponse> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IOcorrenciaResponse>(`${url}/OcorrenciasRonda`, body);

      if (response?.data?.status === 0)
        return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },

  gerProgramacoesRonda: async function (body: IBodyProgramacaoRonda): Promise<IProgramacaoRondasResponse> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IProgramacaoRondasResponse>(`${url}/ProgramacaoRonda`, body);

      if (response?.data?.status === 0)
        return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  },

  getAnexosOcorrencia: async function (body: IBodyAnexoOcorrencias): Promise<IAnexosOcorrenciaResponse> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IAnexosOcorrenciaResponse>(`${url}/RecuperaArquivo`, body);

      if (response?.data?.status === 0)
        return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    } catch (error) {
      return { error: true, message: error };
    }
  }
}

export default APIRonda