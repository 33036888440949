const obj = {
  title: "defaultTheme",
  colors: {
    primary: "#2991D6",
    silverBG: "#FAFAFD",
    white: "#fff",
    black: "rgba(0,0,0,.8)",
    pureBlack: "#000",
    halfOpacityBlack: "rgba(0,0,0,.5)",
    lightGray: '#f7f7f7',
    gray: '#cecece',
    darkSilver: "#000014",
    silverChumbo: "rgba(68, 75, 86, .42)",
    red: "#db504a",
    pink: "rgba(255, 99, 132, 0.9)",
    lightPink: "rgba(255, 99, 132, 0.5)",
    blue: "rgba(40, 145, 214, .9)",
    babyBlue: "rgba(40, 145, 214, .5)",
    greenyGray: "rgba(38, 70, 83, .5)",
    pine: "rgba(38, 70, 83, .9)",
    creamYellow: "rgba(233, 196, 106, .5)",
    royalYellow: "rgba(233, 196, 106, .9)",
    lightOrange: "rgba(233, 114, 76, .5)",
    orange: "rgba(233, 114, 76, .9)"
  },
  font: "'Quicksand', sans-serif;",
  titleSize: "1rem"
}


export default obj

