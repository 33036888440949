import styled from "styled-components";



interface IProps {
  menuIsOpen: boolean;
}

export const Container = styled.div<IProps>`
  display: grid;
  transition: all .7s;
  grid-template-columns: min-content auto;
 
  grid-template-rows: 60px auto;
  grid-template-areas:
  'aside header'
  'aside content';
  height: 100vh;
  background: ${props => props.theme.colors.silverBG};
  overflow-x: hidden;
  position: relative;
  ${props => props.menuIsOpen && `
   
  `}
  
`