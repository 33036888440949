import { useEffect, useState } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import { IPaginationProps } from "../../@interfaces";
import { Container, NavButton, PageIndicator, PageNavigator } from "./styles";

const Pagination: React.FC<IPaginationProps> = ({ handlePrevPage, handleNextPage, currentPage, totalOfPages }) => {

  return(
    <Container>
      <PageIndicator>
        <span>Página {currentPage}/{totalOfPages}</span>
      </PageIndicator>
      <PageNavigator>
        <NavButton onClick={handlePrevPage} disabled={currentPage === 1}>
          <MdKeyboardArrowLeft color="#fff"/>
        </NavButton>
        <NavButton onClick={handleNextPage} disabled={currentPage === totalOfPages}>
          <MdKeyboardArrowRight color="#fff"/>
        </NavButton>
      </PageNavigator>
    </Container>
  )
}

export default Pagination;