import styled from "styled-components";
import Button from "../../../components/button";

export const Container = styled.form`  
  display: grid;  
  grid-template-columns: 1fr 1fr;
 
  gap: 20px 50px;
  @media(max-width: 1058px) {   
    grid-template-columns: 1fr;
    gap: 30px;
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 20px 20px;
  
`

export const Title = styled.h1`
  font-size: ${props => props.theme.titleSize};
  margin-bottom: 30px;
  font-weight: normal;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const FormLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  font-size: .875rem;
`

interface IRowProps {
  template: string;
}

export const FormRow = styled.div<IRowProps>`
  display: grid;
  grid-template-columns: ${props => props.template};
  width: 100%;
  gap: 10px;
`


export const Error = styled.p`
  color: red;
  font-style: italic;
  font-size: 14px;
`

export const SubmitButton = styled(Button)`
  padding: 6px;
  border-radius: 0;
`