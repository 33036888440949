import React, { useContext, useEffect, useState, useMemo } from "react";

import MenuContext from "../../contexts/menu";
import AuthContext from "../../contexts/auth";
import {
  Container,
  CompanyContainer,
  LogoContainer,
  CompanyName,
  MenuList,
  Version
} from "./styles"


import MenuItem from "../menuitem";
import aux from "../../utils/auxiliar";
import storage from "../../storage"
import { name, version } from "../../../package.json"

let menuItems = [
  { id: 0, label: 'Home', link: '/' },
  { id: 1, label: 'Rondas', link: 'rondas' },
  { id: 2, label: 'Usuarios', link: 'usuarios' },
  { id: 3, label: 'Faturas e Boletos', link: 'financeiro' },
  { id: 4, label: 'Ponto', link: 'ponto' },
  { id: 5, label: 'Funcionários', link: 'funcionarios' },
  { id: 6, label: 'Documentos', link: 'documentos' },
  { id: 7, label: 'Quadro de Vagas', link: 'quadro' }
]

const Aside: React.FC = ({ children }) => {
  const { isOpen, currentPage, navigateTo } = useContext(MenuContext);
  const { loggedEmail, isMasterUser } = useContext(AuthContext)
  const [group, setGroup] = useState<string>("");
  const [logo, setLogo] = useState<string | null>(null);

  const _isMaster = isMasterUser();



  useEffect(() => {
    const group = storage.getGroupLogged();
    if (group)
      setGroup(group)
    const _logo = storage.getLogoGroupLogged();

    if (_logo && _logo !== "null") {
      setLogo(_logo)
    }
  }, [])

  return (
    <Container>
      <CompanyContainer>
        {logo && <LogoContainer>
          <img src={logo} alt="logo" />
        </LogoContainer>}
        <CompanyName>{group}</CompanyName>
        <span>{loggedEmail}</span>
        <span>Portal do Cliente</span>
      </CompanyContainer>
      <MenuList>
        {menuItems.map((item, index) => {
          if (item.id === 2) {
            if (!_isMaster) return null;
          }
          return (
            <li key={index}>
              <MenuItem
                label={item.label}
                link={item.link}
                Icon={aux.resolveMenuIcon(item.id)}
                isActive={currentPage === item.link}
              />
            </li>
          )
        })}
      </MenuList>
      <Version>v{version}</Version>
    </Container>
  )
}

export default Aside;