import InfoCard from "../../../components/infocard";
import { IUserCardProps } from "../../../@interfaces";
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';

const UserCard: React.FC<IUserCardProps> = ({ user, handleSetUserToUpdate, handleSetUserToDelete }) => {
  return (
    <InfoCard>
      <div>
        <div>
          <label>Nome</label>
          <p>{user.nome}</p>
        </div>

        <div>
          <label>E-mail</label>
          <p>{user.email}</p>
        </div>

      </div>

      <div>
        <div>
          <label>Telefone</label>
          <p>{user.ddi} {user.ddd} {user.telefone}</p>
        </div>

        <div>
          <label>Ramal</label>
          <p>{user.ramal}</p>
        </div>

      </div>

      <div>
        <div>
          <label>Usuário master</label>
          <p>{user.master ? 'Sim' : 'Não'}</p>
        </div>
        <div>
          <a onClick={() => handleSetUserToUpdate(user.id!)}>
            <AiOutlineEdit size={20} />
          </a>
          <a onClick={() => handleSetUserToDelete(user.id!)}>
            <AiOutlineDelete size={20} />
          </a>
        </div>
      </div>
    </InfoCard>
  )

}

export default UserCard;