import { BodyArquivoDownloadRequest, BodyProdutosRequest, IResponseArquivoDownload, IResponseProdutos } from "../@interfaces";
import { Produto } from "../@types";
import storage from "../storage";
import Api from "./APIAxios";



const APIQuadroVagas = {
  getProdutos: async function (body: BodyProdutosRequest): Promise<IResponseProdutos> {
    console.log(body);
    try{
      const url = storage.getClientUrl();
      const response = await Api.post<IResponseProdutos>(`${url}/QuadroVagas`, body);
      // await new Promise(resolve => setTimeout(resolve, 2000));
      // const response = { data: {
      //   status: 0,
      //   mensagem: 'ok',
      //   produtos: _mock
      // } }
      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    }catch(error){
      return { error: true, message: error };
    }

  },
    
  
 }



 export default APIQuadroVagas;