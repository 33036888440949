import styled from "styled-components"

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgba(0,0,0,.8);
  z-index: 1010;
  top: 0;
  left: 0;
  >iframe{
    max-height: calc(100vh - 52px);
  }
`

export const Header = styled.div`
  background: #000;
  width: 100%;
  height: 50px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;  
  gap: 20px;
  >button{
    border:none;
    background: none;
    color: #fff;
    cursor: pointer;
  }
`