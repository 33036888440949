import React, { createContext, useState } from "react";
import { useLocation } from "react-router-dom";

interface IMenuContext {
  isOpen: boolean;
  toggleMenu: () => void;
  navigateTo: (route: string) => void;
  currentPage: string;
}

const MenuContext = createContext<IMenuContext>({} as IMenuContext)

export const MenuContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  let auxLocation = location && location.pathname ? location.pathname.split('/') : '/';
  if (auxLocation && auxLocation.length > 1) auxLocation = auxLocation[1];
  else auxLocation = '/';

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<string>(!auxLocation ? '/' : auxLocation);




  function toggleMenu() {
    setIsOpen(old => !old);
  }

  function navigateTo(route: string) {
    setCurrentPage(route);
  }

  return (
    <MenuContext.Provider value={{
      isOpen,
      toggleMenu,
      currentPage,
      navigateTo
    }}>
      {children}
    </MenuContext.Provider>
  )
}

export default MenuContext;