import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;  
 
`

export const Options = styled.div`
  max-height: 200px;
  overflow-y: auto;
  > div {
    padding: 10px 0;
    input {
      margin-right: 15px;
    }
  }
  p {
    color: red;
    font-style: italic;
    font-size: 14px;
  },
  label{
    font-size:.875rem;
  }
`