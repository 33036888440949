import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { IPropsChangePassIntoApp } from "../../@interfaces";

import Button from "../../components/button";
import Input from "../../components/input";

import ToastContext from "../../contexts/toast";
import ApiUser from "../../services/APIUser";


import { Container, BallOverlay, Title, Close } from "./styles";
import { PasswordChangeForm } from "./styles";



const PasswordChange: React.FC<IPropsChangePassIntoApp> = (props) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordRep, setNewPasswordRep] = useState<string>("");
  const [arePasswordsDifferent, setArePasswordsDifferent] = useState<boolean>();
  const { showErrorToast, showSuccessToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const theme = useTheme();

  async function handleClick() {
    try {

      if (!newPassword || !newPasswordRep) {
        showErrorToast('Preencha todos os campos');
        return;
      }

      if (newPassword !== newPasswordRep) {
        showErrorToast('Senhas divergentes.')
        setArePasswordsDifferent(true);
        return;
      }

      setArePasswordsDifferent(false);

      const body = {
        novasenha: btoa(newPassword),
        confirmacaonovasenha: btoa(newPasswordRep),
      };

      setLoading(true);
      const result = await ApiUser.changePasswordIntoApplication(body);
      setLoading(false);

      if (!result.error) {
        showSuccessToast('Senha alterada com sucesso.')
        if (props.handleSuccess) {
          props.handleSuccess(result.token!);
        } else {
          history.push('/')
        }
      } else {
        showErrorToast(result.message);
      }

    } catch (error) {
      setLoading(false);
      showErrorToast("Ops... Algo deu errado: " + error);
    }

  }

  return (
    <Container>

      {props.close && <Close handleClose={props.close}></Close>}

      <BallOverlay />

      <Title>Troca de senha</Title>

      <PasswordChangeForm autoComplete="off">
        <Input
          isPassword
          type="password"
          autoComplete="off"
          placeholder="Digite a nova senha (mínimo 8 dígitos)"
          value={newPassword}
          onChange={event => setNewPassword(event.target.value)}
        />
        <Input
          isPassword
          placeholder="Confirme a nova senha"
          type="password"
          value={newPasswordRep}
          colorOfBorder={arePasswordsDifferent ? theme.colors.red : ''}
          onChange={event => setNewPasswordRep(event.target.value)}
        />
        <Button onClick={handleClick} loading={loading}>Confirmar troca</Button>
      </PasswordChangeForm>
    </Container >
  )
}

export default PasswordChange;