import { BodyArquivoDownloadRequest, IResponseArquivoDownload } from "../@interfaces";
import storage from "../storage";
import Api from "./APIAxios";

const APIArquivos = {
  getArquivo: async function (body: BodyArquivoDownloadRequest): Promise<IResponseArquivoDownload> {
      try{
      const url = storage.getClientUrl();
      const response = await Api.post<IResponseArquivoDownload>(`${url}/RecuperaArquivo`, body);
      if (response?.data?.status === 0 || response?.data?.status === 126) return response.data;

      return { error: true, message: response.data?.mensagem ? response?.data?.mensagem : "Erro Desconhecido" };
    }catch(error){
      return { error: true, message: error };
    }

  },
    
  
 }



 export default APIArquivos;