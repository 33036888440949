import Docs from "../../components/docs";
import aux from "../../utils/auxiliar";

const Documentos: React.FC = () => {
  const dataAtual = aux.getCurrentDate();

  return(
    <Docs startDate={dataAtual} finalDate={dataAtual} />
  )
}

export default Documentos;
