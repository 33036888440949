import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from{
    opacity: 0;
  }to{
    opacity: 1;
  }
  
`

export const Container = styled.div`
  grid-area: content;
 
  background-color: ${props => props.theme.colors.silverBG};

  max-width: 100%; 
  // overflow: hidden;
  height: calc(100vh - 70px);
  overflow-y: scroll;

  padding: 20px 20px 10px;
`

export const BallOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100vh;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: ${props => props.theme.colors.white};
`

export const Overlay = styled.div`
 
  @media(max-width: 700px){
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.5);
    z-index: 1001;
    top: 0px;
    left: 220px;
    animation: ${fadeIn} 1.5s forwards;
  }
`