import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Input from '../../input';
import Button from '../../button';

import {
  Container,
  Content,
  ItensContainer,
  Footer
} from './styles';


import { IOptionsProps } from '../../../@interfaces';
import aux from '../../../utils/auxiliar';
import { orderBy } from "lodash"
import { useTheme } from "styled-components"

const Options: React.FC<IOptionsProps> = ({ close, selectOption, itens, sortAlphabetically }) => {
  const [_itens, setItens] = useState(itens);
  const [_itensToShow, setItensToShow] = useState(sortAlphabetically ? orderBy(itens!, ["label"], ["asc"]) : itens);
  const [filterValue, setFilterValue] = useState('')
  const theme = useTheme();

  useEffect(() => {
    setItens(itens);
  }, [itens])


  function handleItemClick(value: string | number) {
    close();
    selectOption(value);

  }



  const filter = useCallback((valueFilter: any) => {
    let value = valueFilter.target.value;

    setFilterValue(value);

    if (!_itens) return [];

    if (value === '') {
      return setItensToShow(_itens);
    }

    const aux = [];

    for (let item of _itens) {
      if (item.label.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
        aux.push(item);
      }
    }

    return setItensToShow(aux);
  }, [])



  return (
    <Container>
      <Content>
        <Input placeholder={"Buscar..."} isSearch colorOfBorder={theme.colors.lightGray} value={filterValue} onChange={filter} />
        <ItensContainer>
          <ul>
            {_itensToShow?.map(i => <li onClick={() => handleItemClick(i.value)} key={i.value}>{i.label}</li>)}
          </ul>
        </ItensContainer>
        <Footer>
          <Button width={100} onClick={close}>Fechar</Button>
        </Footer>
      </Content>
    </Container>
  )
}

export default Options;

