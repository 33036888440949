import React, { useContext, useState } from "react";

import { FiLogOut } from "react-icons/fi"
import MenuContext from "../../contexts/menu";
import AuthContext from "../../contexts/auth";
import { CustumerContext } from "../../contexts/custumer";
import Select from "../select";
import Options from "../select/options";

import {
  Container,
  Separator,
  SectionHeader,
  LogoutButton
} from "./styles"
import { useHistory } from "react-router-dom";


const Content: React.FC = ({ children }) => {
  const { isOpen } = useContext(MenuContext);
  const { signOut } = useContext(AuthContext);
  const { current_custumer_description, selectCurrentCustumer, custumer_list, deleteList } = useContext(CustumerContext)
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const history = useHistory();

  function logOff() {
    signOut();
    deleteList();
    history.push("/");
  }

  return (
    <>
      {showOptions && <Options close={() => setShowOptions(false)} selectOption={selectCurrentCustumer} itens={custumer_list} sortAlphabetically />}
      <Container>
        <SectionHeader innerPos="flex-start">
          

          <Select
            noBorder={true}
            noBackgroundColor={true}
            onOpenOptions={() => setShowOptions(true)}
            label={current_custumer_description}
          />
        </SectionHeader>



        <SectionHeader innerPos="flex-end">

          <LogoutButton onClick={logOff}>
            <span>Sair</span>
            <FiLogOut />
          </LogoutButton>
        </SectionHeader>



      </Container>
    </>
  )
}

export default Content;