import { Container } from "./styles"

const InfoCard: React.FC = ({ children }) => {
  return(
    <Container>
      {children}
    </Container>
  )
}

export default InfoCard;