import React from 'react';
import { LabelContainer } from './styles';
import { FiEdit } from 'react-icons/fi';

import { ISelectionLabel } from '../../@interfaces';
import { useTheme } from 'styled-components';

const SelectionLabel: React.FC<ISelectionLabel> = ({ label, onClick, style }) => {
  const theme = useTheme();

  return (
    <LabelContainer type="button" onClick={onClick} style={style}>
      <p>{label}</p>
      <FiEdit size={16} color={theme.colors.pureBlack} />
    </LabelContainer>
  )
}

export default SelectionLabel;