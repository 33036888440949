import {  useContext, useEffect, useState } from "react";
import { BsChevronCompactDown } from "react-icons/bs";
import { DropDown } from "../styles"; 
import ModalFilters from "../../../components/modalfilters";
import Options from "../../../components/select/options";
import { Input } from "../styles";
import { ComboInputs, Container, InputContainer, RadioOptions, Warning } from "./style";
import { Filtros, SelectOptions, TipoDocumentoOpcoes } from "../../../@types";
import APIPonto from "../../../services/APIPonto";
import Utils from "../../../utils/auxiliar"
import { CustumerContext } from "../../../contexts/custumer";
import aux from "../../../utils/auxiliar";


interface IDoctypeFilterProps {
  handleClose: () => void,
  applyFilter: (filters: Filtros) => void,
  filters: Filtros;
  docTypeOptions: TipoDocumentoOpcoes[];
  docFuncionario?: boolean;
}




const DocFilter: React.FC<IDoctypeFilterProps> = (props) => {

  const [currentFilterValues, setCurrentFilterValues] = useState<Filtros>(props.filters);
  const [isDocTypeOptionsOpen, setIsDocTypeOptionsOpen] = useState<boolean>();
  const [docTypeWarning, setDocTypeWarning] = useState<boolean>();

  const [erros, setErros] = useState<string[]>();
  const [tipoSelecionado, setTipoSelecionado] = useState<TipoDocumentoOpcoes>();
  const [empresas, setEmpresas] = useState<SelectOptions[]>();
  const [carregandoEmpresas, setCarregandoEmpresas] = useState<boolean>();
  const [empresaSelecionada, setEmpresaSelecionada] = useState<number | string>();
  const [opcoesEmpresaAberto, setOpcoesEmpresaAberto] = useState<boolean>();


  const [locais, setLocais] = useState<SelectOptions[]>();
  const [carregandoLocais, setCarregandoLocais] = useState<boolean>();
  const [opcoesLocalAberto, setOpcoesLocalAberto] = useState<boolean>();


  const { id_current_custumer } = useContext(CustumerContext);

  const { dataInicial, dataFinal, alocacao, assinatura, tipoDocumento, empresa, local } = currentFilterValues;


  useEffect(() => {
    setTipoSelecionado(props.docTypeOptions.find(item => item.value === tipoDocumento));
  }, [])



  useEffect(() => {
    if(!!tipoSelecionado && !props.docFuncionario) carregaEmpresas();
    if(tipoSelecionado?.grupoFiltros === 'cliente') carregaLocais();
  }, [tipoSelecionado])




  async function carregaEmpresas(){
    setCarregandoEmpresas(true);
    try{
      const resposta = await APIPonto.getCompanies();
      if (!resposta.error) {
        setEmpresas(Utils.formartForOptionComponent(resposta.empresas!, 'empresa', 'nome'))
      } else {
        // setLoading(false);
        // showErrorToast(`Não foi possível carregar as empresas: ${places.mensagem}`);
        return;
      }
    }catch{
      return;
    }finally{
      setCarregandoEmpresas(false);
    }

  }



  async function carregaLocais(){
    setCarregandoLocais(true);
    try{
      const resposta = await APIPonto.getPlacesList({ cliente: id_current_custumer, SomentePontoEletronico: false });

      if (!resposta.error) {
        setLocais(Utils.formartForOptionComponent(resposta.local!, 'local', 'descricao'))
      } else {
        // setLoading(false);
        // showErrorToast(`Não foi possível carregar as empresas: ${places.mensagem}`);
        return;
      }
    }catch{
      return;
    }finally{
      setCarregandoLocais(false);
    }

  }




  function handleValueChange(value: string | number, filter: string){
    setErros(erros?.filter(erro => erro !== filter));
    const newValues = {...currentFilterValues, [filter]: value};
    setCurrentFilterValues({...newValues});
  }

  


  function handleApplyFilter(){


    if(!tipoDocumento) {
      setDocTypeWarning(true);
      return;
    }

    let camposObrigatorios = ['dataInicial'];

    if(!props.docFuncionario) camposObrigatorios = [...camposObrigatorios, 'empresa'];
    else camposObrigatorios = [...camposObrigatorios, 'dataFinal']

    const erros = aux.checarErros(camposObrigatorios, currentFilterValues);
    
    if(erros.length > 0) return setErros(erros);

    props.applyFilter(currentFilterValues);
    props.handleClose();
  }




  function handleCleanFilter(){
    setCurrentFilterValues(props.filters);
  }




  const _alocacaos = [
    {value: "tafTodos", label: "Todos"},
    {value: "tafEfetivo", label: "Efetivo"},
    {value: "tafTemporario", label: "Temporário"},
  ]




  const _signatures = [
    {value: "tacTodos", label: "Todos"},
    {value: "tacAssinado", label: "Assinado"},
    {value: "tacNaoAssinado", label: "Não assinado"},
  ]



  function handleSelectDocType(value: string | number){
    handleValueChange(value, 'tipoDocumento');
    setTipoSelecionado(props.docTypeOptions.find(item => item.value === value)!)
  }



  function handleSelectEmpresa(value: string | number){
    handleValueChange(value, 'empresa');
    setEmpresaSelecionada(value);
  }




  function handleSelectLocal(value: string | number){
    handleValueChange(value, 'local');
    setEmpresaSelecionada(value);
  }




  function openDocTypeOptions(){
    setDocTypeWarning(false);
    setIsDocTypeOptionsOpen(true);
  }




  function closeDocTypeOptions(){
    setIsDocTypeOptionsOpen(false);
  }




  return(
    <>
    {isDocTypeOptionsOpen && <Options itens={props.docTypeOptions} close={closeDocTypeOptions} selectOption={handleSelectDocType}/>}
    {opcoesEmpresaAberto && <Options itens={empresas} close={() => setOpcoesEmpresaAberto(false)} selectOption={handleSelectEmpresa}/>}
    {opcoesLocalAberto && <Options itens={locais} close={() => setOpcoesLocalAberto(false)} selectOption={handleSelectLocal}/>}

    <ModalFilters {...props} applyFilter={handleApplyFilter} cleanFilter={handleCleanFilter} width={700}>

      <Container>
        <InputContainer>
          <span>Tipo de Documento *</span>

          <DropDown style={{ padding: 10, width: 300, marginTop: 10, border: docTypeWarning ? '1px solid red' : 'none'}} onClick={openDocTypeOptions}>
            <span>{tipoDocumento ? props.docTypeOptions.find(item => item.value === tipoDocumento)?.label :"Escolha do tipo de documento"}</span>
            <BsChevronCompactDown />

          </DropDown>
          {docTypeWarning && <Warning>Campo obrigatório </Warning>}

        </InputContainer>
        
        {(props.docFuncionario || !!tipoSelecionado) && <InputContainer>
          <span>{!props.docFuncionario ? 'Ano e Mês *':'Período'}</span>
          <ComboInputs>
            <Input 
              type={!props.docFuncionario ? 'month':'date'} 
              value={dataInicial} 
              onChange={event => handleValueChange(event.target.value, 'dataInicial')}
              max={dataFinal}
              style={{ border: erros?.includes('dataInicial') ? '1px solid red' : 'none' }}
            />
          {(props.docFuncionario) && <><span> - </span>
            <Input 
              type={'date'} 
              value={dataFinal} 
              onChange={event => handleValueChange(event.target.value, 'dataFinal')}
              min={dataInicial}
              style={{ border: erros?.includes('dataFinal') ? '1px solid red' : 'none' }}
            /></>}
          </ComboInputs>
        </InputContainer>}

        


      {!props.docFuncionario && !!tipoSelecionado && !carregandoEmpresas && 
        <InputContainer>
          <span>Empresa *</span>
          <DropDown style={{ padding: 10, width: 300, marginTop: 10, alignSelf: 'self-start',border: erros?.includes('empresa') ? '1px solid red' : 'none'}} onClick={() => setOpcoesEmpresaAberto(true)}>
            <span>{!!empresa ? empresas?.find(item => item.value === empresa)?.label :"Escolha a empresa"}</span>
            <BsChevronCompactDown />
          </DropDown>
        </InputContainer>
      }


      {(carregandoEmpresas || carregandoLocais) && <span style={{marginTop: 10}}>Aguarde...</span>}





      {tipoSelecionado?.grupoFiltros === 'cliente' && !carregandoLocais &&
        <InputContainer>
          <span>Local</span>
          <DropDown style={{ padding: 10, width: 300, marginTop: 10, alignSelf: 'self-start'}} onClick={() => setOpcoesLocalAberto(true)}>
            <span>{!!local ? locais?.find(item => item.value === local)?.label :"Todos"}</span>
            <BsChevronCompactDown />
          </DropDown>
        </InputContainer> 
      }




          
        {tipoDocumento === 1 && props.docFuncionario && <>
        <InputContainer>
          <span>Tipo de alocação</span>

          <RadioOptions>
            {_alocacaos.map(item => 
              <div>
                <input 
                  type={'radio'} 
                  name="alocacao"
                  value={alocacao} 
                  onChange={() => handleValueChange(item.value, "alocacao")}
                  checked={alocacao === item.value}
                />
                <label>{item.label}</label>
              </div>
              )
            }
          </RadioOptions>
          
        </InputContainer>


        <InputContainer>
          <span>Situação de assinatura</span>

          <RadioOptions>
            {_signatures.map(item => 
              <div>
                <input 
                  type={'radio'} 
                  name="signature_status" 
                  value={assinatura} 
                  onChange={() => handleValueChange(item.value, "assinatura")}
                  checked={assinatura === item.value}
                />
                <label>{item.label}</label>
              </div>
              )
            }
          </RadioOptions>

        </InputContainer>
        </>}
       
      </Container>
    </ModalFilters>
    </>
  )
}

export default DocFilter;