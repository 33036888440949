import styled from "styled-components"





interface IButton {
  disabled?: boolean;
}

export const TableContainer = styled.div`
  overflow: auto;
   
  width: 100%;
  max-width: 100%;
  > table{
    border-collapse: collapse;        
    width: 100%;
    border: 1px solid #eee   
  }

  >table thead{
    text-align: left;    
    width:100%;    
  }

  >table thead th{
    background: #fff;
    z-index: 444;
    padding: 10px;
    font-size: .875rem;
    font-weight:520; 
    position: sticky;
    top: 0;
    text-align: left;
    border: none;
  }

  table tbody tr:nth-child(even){
    background:#f2f2f2;
  }

  table tbody tr:nth-last-child(1){
    background:#fff;
  }

  #actionButton{
    cursor:pointer;    
  }
  
`

interface ITableDataProps {
  alignRight?: boolean
}

export const TableData = styled.td<ITableDataProps>`
  padding: 5px 5px 5px;
  font-size: .75rem;
  font-weight: 400;
  border: 1px solid #eee;
  line-height: 20px; 
  vertical-align:middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props => props.alignRight && `text-align: right;`}
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;


  a {
    display: flex;
  }
`


export const ControllContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding: 5px 0;
  width: 100%;
  background: ${props => props.theme.colors.white};
  border: none;
`


export const Select = styled.select`
  background: #eee; 
  color: ${props => props.theme.colors.primary};
  border: none; 
  outline: none;
`


export const ContainerPage = styled.div`
  padding: 10px;
  background: #eee;
  border-radius: 10px;
  >span{
    font-size: .75rem;
    letter-spacing: 1px;
    color: ${props => props.theme.colors.primary}
  }
  display: flex;
`


export const ButtonControllContainer = styled.div`
  padding: 10px;
  background: #eee;
  border-radius: 10px;  
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
`

export const ButtonControll = styled.button<IButton>`
  border:none;
  width: 25px;
  height: 25px;
  background: ${props => props.theme.colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;  

  ${({ disabled }) => {
    if (disabled) {
      return `background: rgba(0,0,0,.2)`
    }
  }}

`

