import styled, { useTheme } from "styled-components"
import { MdOpenInNew } from "react-icons/md"
import { IoMdClose } from "react-icons/io"
import { BsDownload } from "react-icons/bs"


interface IColumnBoxProps {
  right?: boolean;
}

interface IcontainerProps {
  isOpen?: boolean;
  noShadow?: boolean
}


export const Section = styled.div`
  width: 100%;
  margin-top: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  

  .fixTableHead th, td {    
    border-collapse: collapse;
    padding: 4px;
  }

  .fixTableHead  table thead{    
    text-align: left;    
    width:100%;    
  }

  .fixTableHead table thead th{
    background: #E6E9EB;
    z-index: 99999;
  }
  

  .fixTableHead table thead th{
    padding: 10px;
    font-size: .875rem;
    font-weight: 500;    
  }

  .fixTableHead  table tbody tr td{
    padding: 10px;
    font-size: .75rem;
    font-weight: 400;
    border: 1px solid #eee
  }



  .fixTableHead {
    overflow-y: auto;
    height: 100%;  
  }
  
  .fixTableHead thead th{
    position: sticky;
    top: 0;
    text-align: left;
    
  }

  .fixTableHead table{
    border-collapse: collapse;        
    width: 100%;
    border: 1px solid #eee
  }

  .fixTableHead table tbody tr:nth-child(even){
    background:#f2f2f2;
  }
  
`

export const Container = styled.div<IcontainerProps>`    
  display:flex;   
  flex-direction: column;
  width: 100%;
  border: 1px solid #e3e8ed;
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
  align-items: center;  
  transition: all .2s;
  transition-timing-function: ease-in;
  
  .title{
    font-size:0.875rem;
    font-weight: 500;
    margin-bottom: 8px;
    color:${props => props.theme.colors.darkSilver};
  }
  .value{
    font-size:0.75rem;
    font-weight: 300
  }
  .value_link{
    font-size:0.75rem;
    font-weight: 500;
    cursor: pointer;
    color: #2991D6;
    text-decoration: none
  }
  #tot{
    font-size: .75rem
  }  
  box-shadow: ${({ noShadow }) => {
    if (!noShadow) return ` rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;`
  }}
  

  ${({ isOpen }) => {
    if (isOpen) {
      return `
        transition: all .4s;
        transition-timing-function: ease;
        position:absolute;
        top: 0px;
        max-width: 100%;
        z-index: 4444;
        background: yellow;
        align-items: flex-start;        
        background: #fff;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
        transform: translateY(-7px);
        
      `
    }
    return ``;
  }};


`


export const Separator = styled.div`
  width: 2px;
  height: 100%;
  background: #eeeef0;
  margin: 0 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
  @media(max-width: 700px){
    display: none;
  }
`

export const NotaNumber = styled.div`
 background-color: ${props => props.theme.colors.primary};
 background-image: linear-gradient(315deg, ${props => props.theme.colors.primary} 0%, #2195dd 74%);
 padding: 2px 10px 3px;
 border-radius: 18px;
 height: 22px;
 >span{
    color: ${props => props.theme.colors.white};
    font-size: .875rem
  }
 margin-right: 10px;
`

interface IGridProps {
  lastColumnSize: number
}

export const Grid = styled.div<IGridProps>`  
  display: grid;
  grid-template-columns: repeat(6, 1fr) ${props => props.lastColumnSize}px;
  width: 100%;
  align-items: center;
  gap: 10px;
 
`

export const ColumnBox = styled.div<IColumnBoxProps>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  word-break: break-all;
  ${({ right }) => {
    if (right) return ` align-items: flex-end;`
  }}
`

export const RowBox = styled.div`
  display: flex;  
  flex-direction: row;
  width: 100%;
`


export const IconOpen = styled(MdOpenInNew).attrs(props => ({
  color: props.theme.colors.primary,
  size: 18
}))`
  cursor: pointer;
`

export const IconClose = styled(IoMdClose).attrs(props => ({
  color: props.theme.colors.primary,
  size: 18
}))`
  cursor: pointer;
`

export const IconDownload = styled(BsDownload).attrs(props => ({
  color: props.theme.colors.primary,
  size: 18
}))`
  cursor: pointer;
`

interface ILabelProps {
  isActive?: boolean
}

export const LabelBoleto = styled.a<ILabelProps>`
  font-size: .75rem;
  font-weight: bold;
  text-align: center;
  color: ${({ theme, isActive }) => isActive ? theme.colors.primary : 'rgba(0, 0, 0, .25)'};

   ${({ isActive }) => isActive && `
    :hover{
      cursor: pointer;
    } 
    `}
  
`