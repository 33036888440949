import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
`

const inputStyle = css`
	border: none;
	border-radius: 8px;
	background: #fff;
	padding: 7px 15px;
	width: 200px;
	display: flex;
	justify-content: space-between;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	outline: none;
	color: rgba(0,0,0,.5);
`

interface IInputProps{
  erro?: boolean
}

export const Dropdown = styled.button<IInputProps>`
	${inputStyle}

  ${props => props.erro && `
    border: 1px solid red;
  `}

	:hover{
		cursor: pointer;
	}

	span{
		color: rgba(0,0,0,.5);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
	}
`



export const Input = styled.input<IInputProps>`
	${inputStyle}
  
  ${props => props.erro && `
    border: 1px solid red;
  `}

`