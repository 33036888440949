import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  a {
    color: ${props => props.theme.colors.blue};

    :hover{
      cursor: pointer;
    }
  }
`


export const Title = styled.h1`
  font-size: 1rem;
  margin: 20px 0 30px;
  color: ${props => props.theme.colors.halfOpacityBlack};
`

export const TypeList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  
`

export const TypeItem = styled.li`
  padding: 10px 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 1rem;
  color: ${props => props.theme.colors.halfOpacityBlack};
`


export const ErrorMessage = styled.span`
  font-size: .857rem;
  color: ${props => props.theme.colors.halfOpacityBlack};

`



export const InputContainer = styled.div`
  width: 100%;
  padding: 15px 20px;
  border-bottom: 2px solid ${props => props.theme.colors.lightGray};

  span:first-child {
    color: ${props => props.theme.colors.black};
  }

`


export const ComboInputs = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  gap: 10px;
`




export const RadioOptions = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 15px;
  > div{
    display: flex;
    align-items: center;
    gap: 5px;
  }

  input {
    margin: 0;
  }

  label {
    font-size: .875rem;
    color: ${props => props.theme.colors.black}
  }
`


export const Warning = styled.span`
  color: ${props => props.theme.colors.red}; 
  font-size: .75rem; 
`