import { BodyArquivoDownloadRequest, IResponseArquivoDownload, IResponseNoticias } from "../@interfaces";
import storage from "../storage";
import Api from "./APIAxios";

const ApiNoticias = {
  getNoticias: async function (page: number, cliente: number): Promise<IResponseNoticias> {
    try {
      const url = storage.getClientUrl();
      const response = await Api.post<IResponseNoticias>(`${url}/listaNoticias`, { page, cliente });
      if (response.data && response.data.status === 0 && response.data.noticias) {
        return response.data;
      } else {
        return {
          error: true
        }
      }
    } catch (error) {
      return {
        error: true
      };
    }
  },


}



export default ApiNoticias;