import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 1px solid #cecece;
  
  padding: 2px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    word-break: break-word;
    text-align: right;

    a {
      margin-left: 10px;
    }

    div:first-child {
      text-align: left;
    }
  }

  label {
    font-weight: 500;
    color: ${props => props.theme.colors.black}
    font-size: .875rem!important;
   
  }

  p {
    margin-top: 10px;
    font-size: .75rem;
  }
`