import styled from "styled-components";

export const LabelContainer = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  min-height: 40px;
  transition: background .5s;
  border: none;
  background: none;
  :hover {
    cursor: pointer;
    background: #eee;
  }

  p {
    font-size: 1rem;
  }
`