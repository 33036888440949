import styled from "styled-components";


interface IProps {
  fontSize?: string 
}


export const Message = styled.span<IProps>`
  ${props => !!props.fontSize && `font-size: ${props.fontSize};`}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  color: ${props => props.theme.colors.black};
  font-size: .875rem;
  align-self: center;
  letter-spacing: 1px;
  margin-top: 20px;
  font-weight: bold;

  span:last-child {
    font-weight: 300;
  }
`
